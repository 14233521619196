import React, {useMemo, useState} from "react";
import {ThemeProvider} from "@material-ui/styles";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {createMuiTheme} from "@material-ui/core";
import {Club} from "./custom";

export const embrace = (t) => t > 0 ? `(${t})` : "";
//const t0 = new Date() - (new Date().getTime() % (3600 * 1000));
const t0 = new Date() - (new Date().getTime() % (3600 * 1000));

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: Club.color
        },
    },
});

export function MyTimePicker({pushChange}) {
    const [selectedDate, handleDateChange] = useState(t0);
    const myHandleDateChange = (date) => {
        handleDateChange(date);
        const dt = Math.round((date - t0) / 1000);
        console.log(dt, dt%3600)
        pushChange(dt % 3600);
    };
    return (
        <ThemeProvider theme={defaultMaterialTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    className={"timepicker"}
                    ampm={false}
                    openTo="minutes"
                    a0ok
                    views={["minutes", "seconds"]}
                    format="mm:ss"
                    label="Minuten und Sekunden"
                    value={selectedDate}
                    onChange={myHandleDateChange}
                    cancelLabel={"abbrechen"}
                />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
}

export const encodeGetParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");


export function CloudImageReplacer({children: content}) {
    const contentImageEnhaned = useMemo(() => {
        const div = document.createElement("div")
        div.innerHTML = content;
        const images = div.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
            images[i].src = `https://azamqtcdeo.cloudimg.io/v7/${images[i].src.replace("https://", "")}?width=${images[i].width || 400}`
        }
        return div.innerHTML
    }, [content])
    return <div className={"HTMLCloudImageContainer"} dangerouslySetInnerHTML={{__html: contentImageEnhaned}}/>
}