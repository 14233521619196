import React from "react"
import Consts from "./consts";

type _Name = {
    verein_ID: number,
    fullName: string,
    fullNameDeclined: string,
    shortName: string,
    domain: string,
    title: string,
    logo?: JSX.Element,
    color: string,
    colorLight: string,
    fontColor?: string,
    fontColorInverse?: string,
    showLogo: boolean,
    iban: string,
    facebook?: string,
    memberStates: Record<number, string>,
    memberStatesShort?: Record<number, string>,
    arnName: string,
    arn: string,
    sendReminders: boolean,
    address: string,
    creditorID: string,
    email?: string,
    phone?: number,
    links?: {
        dataprotection?: string
        statuten?: string
    }
}

type _domainMap = Record<string, _Name>

const domainMapInitial: _domainMap = {
    "dahoam.turnfest.at": {
        verein_ID: 1,
        fullName: "Turnfest Dahoam",
        fullNameDeclined: "Turnfests Dahoam",
        shortName: "Turnfest Dahoam",
        domain: "dahoam.turnfest.at",
        title: "Turnfest Dahoam",
        logo: <img alt={"dahoam logo"} src={"http://www.turnfest.at/fileadmin/_processed_/2/a/csm_Turnfest_Dahoam_Header1_eabfbb9246.jpg"}/>,
        showLogo: true,
        color: "#039cbb",
        colorLight: "rgb(197,243,255)",
        fontColor: "#fff",
        fontColorInverse: "#fff",
        iban: "AT12 3456 7890 1234 5678",
        facebook: "Turnverein",
        memberStates: {},
        arnName: "Dahoam.Turnfest.at <dahoam@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Prunerstraße 6, 4020 Linz",
        creditorID: "ATZ12314151515151",
    },
    "jw.turnfest.at": {
        verein_ID: 1,
        fullName: "Turnfest Dahoam",
        fullNameDeclined: "Turnfests Dahoam",
        shortName: "Turnfest Dahoam",
        domain: "jw.turnfest.at",
        title: "Turnfest Dahoam",
        logo: <img alt={"dahoam logo"} src={"http://www.turnfest.at/fileadmin/_processed_/2/a/csm_Turnfest_Dahoam_Header1_eabfbb9246.jpg"}/>,
        showLogo: true,
        color: "#039cbb",
        colorLight: "rgb(197,243,255)",
        fontColor: "#fff",
        fontColorInverse: "#fff",
        iban: "AT12 3456 7890 1234 5678",
        facebook: "Turnverein",
        memberStates: {},
        arnName: "Dahoam.Turnfest.at <dahoam@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Prunerstraße 6, 4020 Linz",
        creditorID: "ATZ12314151515151",
    },
    "bewerbe.turnfest.at": {
        verein_ID: 1,
        fullName: "Turnfest Bewerbe",
        fullNameDeclined: "Turnfests Bewerbe",
        shortName: "Turnfest Bewerbe",
        domain: "bewerbe.turnfest.at",
        title: "Turnfest Bewerbe",
        logo: <img alt={"dahoam logo"} src={"http://www.turnfest.at/fileadmin/_processed_/2/a/csm_Turnfest_Dahoam_Header1_eabfbb9246.jpg"}/>,
        showLogo: true,
        color: "#039cbb",
        colorLight: "rgb(197,243,255)",
        fontColor: "#fff",
        fontColorInverse: "#fff",
        iban: "AT12 3456 7890 1234 5678",
        facebook: "Turnverein",
        memberStates: {},
        arnName: "Dahoam.Turnfest.at <dahoam@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Prunerstraße 6, 4020 Linz",
        creditorID: "ATZ12314151515151",
    }
};
const domainMap: _domainMap = Object.keys(domainMapInitial).reduce((obj, curr) => ({
    ...obj,
    [curr]: {
        ...domainMapInitial[curr],
        iban: domainMapInitial[curr].iban,
        memberStatesShort: Object.keys(domainMapInitial[curr].memberStates)
            .reduce((obj, k) => ({
                ...obj,
                [parseInt(k)]: domainMapInitial[curr].memberStates[parseInt(k)].split(" ").map((u: any) => u[0]).join(".")
            }), {})
    }
}), {});
const domainName = window.location.hostname.split(".").slice(-2).join(".");
const domainNameLong = window.location.hostname.split(".").slice(-3).join(".");
const clubMap = Object.keys(domainMap).reduce((obj, curr) => ({...obj, [domainMap[curr].verein_ID]: curr}), {});


let ClubPart = domainMap[domainNameLong] || (
    (domainName.indexOf("localhost") > -1 || domainName.indexOf("10.0.0") > -1 || !domainMap[domainName]) ?
        domainMap["vereinfacht.at"] :
        domainMap[domainName]
);
if (Consts.f(true, false)) {
    ClubPart = domainMap["dahoam.turnfest.at"];
}

const Club = {
    ...ClubPart,
}; // domain: window.location.host


export {Club, domainMap, clubMap};
