import React, {Component, useEffect,} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import {Switch} from "react-router";
import {Container} from "./core/input_fields";
// ie stuff
import "babel-polyfill";
import {polyfill} from 'es6-promise';
import cssVars from 'css-vars-ponyfill';
import {Club, clubMap, domainMap} from "./core/custom";
import Nav from "./nav";
import CheckLogin from "./user/check_login";

import Login from "./user/login";
import ErrorBoundary from "./core/error_boundary";
import {UserContext} from "./user/UserContext"
import AsyncComponent from "./core/AsyncComponent";
import ChangePW from "./user/changepw";
import {maxiGetObj} from "./core/maxiosLeg";
import Logo from "./images/logo.svg";
import SPLogo from "./images/logo_SPA.svg";
import BinderLogo from "./images/logo_binder_small.jpg";
import Index from "./pages";
import UserEdit from "./user/edit";

polyfill();

// end ie stuff
function ScrollToTop({location}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
}

class App extends Component {
    state = {
        navOpen: localStorage.getItem("mainContainer") === "true",
        navToggle: () => {
            localStorage.setItem("mainContainer", !this.state.navOpen);
            this.setState({navOpen: !this.state.navOpen})
        },
        mounted: false,
        club: (!!localStorage.getItem("vereinID_cache") ? domainMap[clubMap[(parseInt(localStorage.getItem("verein_ID_cache")))]] : Club),
        clubs: JSON.parse(localStorage.getItem("clubs") || "{}"),
    };

    addUserFromStatus = resp => {
        !!resp.club && this.setCssVars(resp.club);
        this.setState(resp);
    };

    setCssVars = club => cssVars({
        rootElement: document,
        variables: {
            "mainColor": club.color,
            "maincolorlight": club.colorLight,
            "fontColor": club.fontColor || "#444",
            "fontColorInverse": club.fontColorInverse || "#fff",
        },
    });

    componentWillMount() {
        const club = (!!localStorage.getItem("vereinID_cache") ? domainMap[clubMap[(parseInt(localStorage.getItem("verein_ID_cache")))]] : Club);
        this.setCssVars(club)
    }

    componentDidMount = () => {
        this.clubsLoad()
    };

    clubsLoad = () => {
        if (localStorage.getItem("clubsUpdated") === null || (new Date()).getTime() - localStorage.getItem("clubsUpdated") > 1000 * 60 * 30) {
            maxiGetObj({
                url: "/clubs", success: ({clubs}) => {
                    const clubsMap = clubs.reduce((obj, curr) => ({...obj, [curr[0]]: {ID: curr[0], name: curr[1]}}), {})
                    localStorage.setItem("clubsUpdated", (new Date()).getTime());
                    localStorage.setItem("clubs", JSON.stringify(clubsMap));
                    this.setState({clubs: clubsMap})
                }
            })
        }

    };

    render() {

        return (
            <Router>

                <UserContext.Provider value={this.state}>
                    <div style={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        fontSize: "10px",
                        backgroundColor: "white",
                        padding: "5px"
                    }}>
                        version {window.vatomatorVersion}
                    </div>
                    <div className={"App " + (window.location.hostname === "localhost" ? "blue" : "tvg")}>
                        <Route path="/" render={(props) => <CheckLogin {...props} addUserFromStatus={this.addUserFromStatus}/>}/>
                        {
                            /*!!this.state.user && this.state.user.status === "logged_in" &&
                             */
                        }
                        <Route path="/" component={Nav}/>
                        <Route path="/" component={ScrollToTop}/>
                        <MainContainer>
                            <div id={"mainOverlayForNav"}/>
                            <ErrorBoundary key={"ljljfs"}>
                                <Switch>
                                    <Route exact path="/benutzer/changepw/:token" component={ChangePW}/>
                                    <Route exact path={"/profil"} component={AsyncComponent(() => import("./user/profil"))}/>
                                    <Route exact path={"/personen/:club_ID/veranstaltung/:event_ID"} component={AsyncComponent(() => import("./user/list"))}/>
                                    <Route exact path={"/personen/veranstaltung/:event_ID"} component={AsyncComponent(() => import("./user/list"))}/>
                                    <Route exact path={"/personen/:club_ID"} component={AsyncComponent(() => import("./user/list"))}/>
                                    <Route exact path={"/personen"} component={AsyncComponent(() => import("./user/list"))}/>
                                    <Route exact path={"/vereine"} component={AsyncComponent(() => import("./user/clubs"))}/>
                                    <Route exact path={"/vereine/veranstaltung/:event_ID"} component={AsyncComponent(() => import("./user/clubs"))}/>
                                    <Route exact path={"/veranstaltungen"} component={AsyncComponent(() => import("./competitions/list"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID"} component={AsyncComponent(() => import("./competitions/list"))}/>
                                    <Route exact path={"/bewerbe/teilnehmen"} component={AsyncComponent(() => import("./competitions/participate"))}/>
                                    <Route exact path={"/bewerbe/teilnehmen/:competition_ID"} component={AsyncComponent(() => import("./competitions/participate"))}/>
                                    <Route exact path={"/bewerbe/:competition_ID"} component={AsyncComponent(() => import("./competitions/detail"))}/>
                                    <Route exact path={"/ergebnisse"} component={AsyncComponent(() => import("./scores/list"))}/>
                                    <Route exact path={"/ergebnisse/verein/:club_ID"} component={AsyncComponent(() => import("./scores/list"))}/>
                                    <Route exact path={"/ergebnisse/person/:users_ID"} component={AsyncComponent(() => import("./scores/list"))}/>
                                    <Route exact path={"/ergebnisse/wettbewerb/:competition_ID"} component={AsyncComponent(() => import("./scores/list"))}/>
                                    <Route exact path={"/ranglisten"} component={AsyncComponent(() => import("./scores/leaderboard"))}/>
                                    <Route exact path={"/ranglisten/:event_ID"} component={AsyncComponent(() => import("./scores/leaderboard"))}/>
                                    <Route exact path={"/ranglisten/:event_ID/verein/:club_ID"} component={AsyncComponent(() => import("./scores/leaderboard"))}/>
                                    <Route exact path={"/ranglisten/:event_ID/wettbewerb/:competition_ID"} component={AsyncComponent(() => import("./scores/leaderboard"))}/>
                                    <Route exact path={"/registrieren"} component={UserEdit}/>
                                    <Route exact path={"/getCustomClub"} component={AsyncComponent(() => import("./core/custom_exporter"))}/>
                                    <Route exact path={"/faq"} component={Index}/>
                                    <Route exact path={"/dahoam"} component={Index}/>
                                    <Route exact path={"/"} component={Index}/>

                                    <Route path={"/"} component={Notfound}/>
                                </Switch>
                                {
                                    window.location.pathname.indexOf("/benutzer/") === -1 && <Container name={"footer"} hideHeading>
                                        <div id={"divider"}/>

                                        <div id={"text"}>

                                            ÖTB OÖ,&nbsp;
                                            {new Date().getFullYear()},&nbsp;
                                            <a target={"_blank"} href={"http://www.turnfest.at"}>turnfest.at</a>, <a target={"_blank"} rel={"noopener noreferrer"} href={"http://www.turnfest.at/index.php?id=448"}>Impressum</a>
                                        </div>
                                        <div id={"images"}>
                                            <a href={"//turnfest.at"} target={"_blank"} rel={"noopener noreferrer"}><img alt={"Turnfest.at Logo"} src={Logo}/></a>
                                            <a href={"//www.sparkasse.at/oberoesterreich/privatkunden"} target={"_blank"} rel={"noopener noreferrer"}><img alt={"Sparkasse OÖ Logo"} src={SPLogo}/></a>
                                            <a href={"//www.sport-binder.at"} target={"_blank"} rel={"noopener noreferrer"}><img alt={"Binder Sportgeräte Logo"} src={BinderLogo}/></a>
                                        </div>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </Container>
                                }
                            </ErrorBoundary>
                        </MainContainer>
                        <article style={{zIndex: 100, position: "fixed", width: "100%"}}>
                            <Route path="/benutzer/login" component={Login}/>
                            <Route path="/benutzer/logout" component={Login}/>

                        </article>

                    </div>
                </UserContext.Provider>
            </Router>
        );
    }
}


class MainContainer extends React.Component {
    state = {
        mounted: false,
    };
    componentDidMount = () => {
        window.setTimeout(() => {
            this.setState({mounted: true})
        }, 2000);
    };
    static contextType = UserContext;

    render() {
        const userContext = this.context;
        return <article style={{zIndex: 10}} id={"mainContainer"}
                        className={
                            (userContext.navOpen ?
                                    "open" :
                                    "closed"
                            ) +
                            (this.state.mounted ? " postLoad" : "")}
                        onClick={() => userContext.navOpen && !!userContext.user && userContext.user.status === "logged_in" && window.innerWidth < 500 && userContext.navToggle()}
        >
            {this.props.children}
        </article>
    }
}

function Notfound(props) {
    if (props.location.pathname.indexOf("/benutzer") > -1) {
        return null
    }
    return <Container name={"Fehler"}>Diese Funktion wird in kürze hinzugefügt!</Container>
}

export default App;
