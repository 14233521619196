import React, {useContext, useMemo, useRef, useState} from "react";
import {EditorInput, LightContainer, MyModal, TextfieldInput} from "../core/input_fields";
import {FormContext, FormContextWrapper} from "../core/form_context";
import {Loader, MaxBtn} from "../core/components";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";
import {FaArrowCircleDown, FaArrowCircleUp, FaFile, FaFileAudio, FaInfoCircle, FaTrash} from "react-icons/all";
import {Link} from "react-router-dom";
import {dateFormatTime} from "../core/dateFuncs";
import axios from "axios";
import Consts from "../core/consts";
import Player from '@vimeo/player';
import UserHistory from "../user/user_history";
import EmailEditor from "react-email-editor";
import {Club} from "../core/custom";
import {CloudImageReplacer} from "../core/helpers";

function VideoR({content, data, editMode}) {
    const [showIFrame, setShowIFrame] = useState(false);
    const videoContainer = useRef();
    const imageContainer = useRef();
    useMemo(() => {
        if (showIFrame) {
            //console.log(videoContainer);
            imageContainer.current.style.display = "none";
            videoContainer.current.style.width = "480px";
            videoContainer.current.style.height = "260px";
            window.setTimeout(() => {
                videoContainer.current.style.width = "auto";
                videoContainer.current.style.height = "auto";
            }, 300);
            const player = new Player(videoContainer.current, {
                id: content.slice(31),
                width: 480,
                height: 270,
                autoplay: true,
            });
        }
    }, [showIFrame]);
    if (editMode) {
        return <TextfieldInput name={"Video URL"} tag={"block_content"} style={{width: "400px"}}/>
    } else {
        if (content.indexOf("vimeo") === -1 || (data || {}).thumbnailID === undefined) {
            return <iframe src={content} width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
        }
        const thumbUrl = "https://i.vimeocdn.com/video/" + ((data || {}).thumbnailID) + ".jpg?mw=480&mh=270&q=70";
        return <>
            <div ref={videoContainer}/>
            {showIFrame ?
                <>
                    {/*<!--<iframe src={content} width="480" height="270" frameBorder="0" allow="autoplay; fullscreen"
                        allowFullScreen/>-->
                        */}

                </> :
                /*<img ref={imageContainer} onClick={() => setShowIFrame(true)} className={"videoContainer"} width="100%" height="100%" style={{cursor: "pointer"}} src={"https://i.vimeocdn.com/video/" + ((data || {}).thumbnailID || 869648478) + ".jpg?mw=480&mh=270&q=70"}/>*/
                <img ref={imageContainer} onClick={() => setShowIFrame(true)} className={"videoContainer"} width="480" height="270" style={{cursor: "pointer"}} src={thumbUrl}/>
            }
        </>
    }
}

function TextR({content, editMode, data, ...rest}) {

    if (editMode) {
        return <>
            <EditorInput name={"Inhalt"} tag={"block_content"} style={{width: "400px"}}/>
        </>
    } else {
        return <CloudImageReplacer>{content}</CloudImageReplacer>;
        return <LightContainer name={data.heading}>
        </LightContainer>

    }
}

function DocumentR({content, editMode, data, ...rest}) {
    const context = useContext(FormContext);
    const setFileData = (data) => {
        context.setState({block: {...context.state.block, data}});
        //console.log(data, context)
    };
    return <div>
        {
            !!data &&
            <a href={(data || {}).fileToken !== undefined ? "https://vereinfacht-public.s3-eu-west-1.amazonaws.com/" + data.fileToken : "#"}
               className={"blockFile"}>
                {
                    {
                        "pdf": <FaFile style={{fontSize: "160px"}}/>,
                        "mp3": <FaFileAudio style={{fontSize: "160px"}}/>
                    } [data.type] || <FaFile style={{fontSize: "160px"}}/>
                }
                <div>
                    {content} {!!data.type && false && `(${data.type})`}
                </div>
            </a>
        }
        {editMode && <>
            <TextfieldInput name={"Name"} tag={"block_content"} style={{width: "200px"}}/>
            <UploadDocument setFileData={setFileData}/>

        </>}
    </div>

}

class UploadDocument extends React.Component {
    constructor(props,) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.fileField = React.createRef();

        this.state = {
            showing: 1,
            loading: false,
            progress: 100,

        };
    }

    saveFile = (close) => (e) => {
        e.preventDefault();
        this.setState({loading: true});

        var formData = new FormData();
        formData.append("document", this.fileField.current.files[0]);
        axios.post(Consts.API_PREFIX + "/page_blocks/document/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.setState({
                    progress: Math.round(progressEvent.loaded / progressEvent.totalSize * 100)
                })
            }
        })
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({
                        error: response.data.message,
                        status: "",
                        loading: false
                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        error: "",
                        [this.fieldName]: response.data.file_name,
                        loading: false, renew: false
                    });
                    this.props.setFileData(response.data)
                }
            })
            .catch((error) => {
                console.log("error", error);
                //this.setState({loading: false})
                this.setState({
                    error: error.message,
                    loading: false,
                    status: ""
                })
            });
    };
    handleChange = (e) => {

        const s = e.target.name.split("_");
        this.setState({
            [s[0]]: Object.assign(
                {},
                this.state[s[0]],
                {[s[1]]: e.target.type === 'checkbox' ? e.target.checked : e.target.value}
            )
        });

    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    render() {
        const {child, trigger} = this.props;

        return <>
            <Status type={"error"} text={this.state.error}/>
            <form id={"profile_picture_form"} onChange={this.handleChange} onSubmit={this.handleSubmit}>
                <div id="excel_file">
                    <label>
                                    <span>
                                        Dokument (PDF, DOCX)
                                    </span>
                        <input
                            style={{paddingTop: "20px"}}
                            type={"file"}
                            ref={this.fileField}
                        />
                        {this.state.loading &&
                        <label>
                            <img alt={"loader"}
                                 src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/> {this.state.progress} % {this.state.progress === 100 &&
                        <span>(verarbeite Dokument)</span>}
                        </label>
                        }
                    </label>
                    <MaxBtn onClick={this.saveFile(() => {
                    })}>Datei uploaden</MaxBtn>
                </div>
            </form>
        </>

    }
}

function withEditableBlock(WrappedComponent) {
    // ...and returns another component...
    return class extends React.Component {
        state = {
            block: this.props.block,
            loading: false,
        };
        apiPost = apiPost.bind(this);
        setState = this.setState.bind(this);

        handleSubmit = (e) => {
            e.preventDefault();
            this.setState({loading: true});
            this.apiPost("/page_blocks/update", this.state.block, resp => {
                window.setTimeout(() => {
                    console.log("ljsldjf")
                    this.setState({loading: false})
                }, 2000)
            })
        };

        render() {
            const {editMode, block, pageLength} = this.props;
            if (editMode) {
                return <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>
                    <div className={"blockMover"}>
                        {
                            <FaTrash onClick={() => {
                                if (window.confirm("Möchtest du diesen Block wirklich löschen?")) {
                                    this.apiPost("/page_blocks/delete", {
                                        block_ID: this.state.block.ID,
                                    }, _ => this.props.reload())
                                }
                            }
                            }/>
                        }
                        {
                            block.slot_index > 0 &&
                            <FaArrowCircleUp onClick={() => {
                                this.apiPost("/page_blocks/move", {
                                    direction: "up",
                                    block_ID: this.state.block.ID,
                                }, _ => this.props.reload())

                            }}/>
                        }
                        {
                            block.slot_index + 1 < pageLength &&
                            <FaArrowCircleDown onClick={() => {
                                this.apiPost("/page_blocks/move", {
                                    direction: "down",
                                    block_ID: this.state.block.ID,
                                }, _ => this.props.reload())

                            }}/>
                        }
                        {
                            <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                <div>
                                    Zuletzt bearbeitet von: <Link
                                    to={"/benutzer/view/" + block.editor_ID}>{block.editor_name}</Link>
                                    <br/>
                                    Zuletzt bearbeitet am: {dateFormatTime(block.timeModified)}
                                    <br/>
                                    Erstellt am: {dateFormatTime(block.timeAdded)}

                                    <br/>
                                    <br/>
                                    <h3>Veränderungen</h3>
                                    <UserHistory userID={block.ID} personType={"block"}/>
                                </div>
                            </MyModal>
                        }
                    </div>
                    <WrappedComponent {...this.props.block} editMode={editMode}/>
                    <Status type={"error"} text={this.state.error}/>
                    <MaxBtn>Speichern</MaxBtn> <Loader loading={this.state.loading}/>
                </FormContextWrapper>
            }
            return <WrappedComponent {...this.props.block} />;
        }
    };
}

const Video = withEditableBlock(VideoR);
const Text = withEditableBlock(TextR);
const Document = withEditableBlock(DocumentR);

export default {Video, Text, Document}