import React from "react";
import {Container, Loader, MaxBtn} from "../core/components";
import {FormContextWrapper} from "../core/form_context";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";
import {CheckboxInput, InputContainer, SelectfieldInput, TagsInput, TextfieldInput} from "../core/input_fields";
import Consts from "../core/consts";
import {FaCheckCircle} from "react-icons/all";


export default class UserEdit extends React.Component {

    setState = this.setState.bind(this);
    apiGet = apiGet.bind(this);
    apiPost = apiPost.bind(this);

    constructor(props) {
        super(props);
        const registerMode = this.props.location?.pathname === "/registrieren"
        this.state = {
            user: registerMode ?
                {
                    country: "AUT"
                } :
                null,
            loading: !registerMode,
            registerMode,
        };

    }

    componentDidMount = () => {
        if (!this.state.registerMode) {
            this.apiGet("/user" + (this.props.user_ID ? "/" + this.props.user_ID : ""), ({user}) => {
                this.setState({user}, () => {
                    this.checkPostalCode()
                })
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state, this.props, this.state[this.props.id])
        if (this.state.registerMode) {
            if (this.state.user.dataprotection !== 1) {
                return this.setState({error: "Bitte das Hakerl bei Einwilligung Datenschutz setzen!"})
            }
        }
        this.setState({loading: true});
        this.apiPost(this.state.registerMode ? "/user/register" : "/user", this.state.user, resp => {
            this.setState({
                success: this.state.registerMode ? "Du hast dich erfolgreich registriert und hast ein E-Mail mit weiteren Anweisungen bekommen" : "Die Daten wurden erfolgreich gespeichert."
            })
            this.props.reload && this.props.reload()
        })
    };
    checkPhone = (e) => {
        const s = e.target.name.split("_");
        const val = this.state[s[0]][s[1]];
        if (s[1].startsWith("phone")) {

            const newVal = Consts.phoneNormalise(val)
            if (newVal !== val) {
                this.setState({[s[0]]: {...this.state[s[0]], [s[1]]: newVal}})
                //console.log(val, newVal)
            }
        }
        if (s[1] === "email") {
            this.apiPost("/user/address/email", {email: val}, resp => {
                if (resp.email === (this.state[s[0]] || {}).email) {
                    this.setState({emailValid: resp.valid})
                }
            })
        }
    };
    checkPostalCode = (e) => {
        if (this.state.user.country !== "AUT" || !this.state.user.postalCode) {
            return
        }
        this.apiGet("/postalCode/" + this.state.user.postalCode, resp => {
            this.setState({postalCode: resp.postalCode})
        })
    };

    render() {
        const {user, error, success, loading, postalCode, registerMode} = this.state;
        return <Container name={registerMode ? "Für Turnfest Dahoam registrieren" : "Benutzerdaten bearbeiten"}>
            <Status type={"error"} text={error}/>
            <Status type={"success"} text={success}/>
            <Loader loading={loading}/>
            {
                (user || registerMode) && (!registerMode || !success) && <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>
                    {
                        ["sex", "prename", "lastname", "birthyear", "email", "country", "postalCode"]
                            .map((key, i) => {
                                if (key === "country") {
                                    return <InputContainer>
                                        <SelectfieldInput name={"Land"} tag={"user_country"} selectives={[["AUT", "Österreich"], ["GER", "Deutschland"]]}/>
                                        {i % 2 === 0 && <br/>}
                                    </InputContainer>
                                }
                                if (key === "sex") {
                                    return [<InputContainer>
                                        <SelectfieldInput name={"Geschlecht"} tag={"user_sex"} demandSelect selectives={[["0", "Weiblich"], ["1", "Männlich"]]}/>


                                    </InputContainer>, <br/>]
                                }
                                if (key === "email") {
                                    return <InputContainer>
                                        <TextfieldInput
                                            onBlur={this.checkPhone}
                                            inputBorderColor={this.state.emailValid === false ? "red" : null}
                                            name={<>{Consts.translate(key)} {this.state.emailValid === true && <FaCheckCircle/>}{this.state.emailValid === false && "(falsch)"}</>}
                                            tag={"user_" + key}
                                        />
                                        {(i % 2 === 1) && <br/>}
                                    </InputContainer>
                                }
                                if (key === "postalCode") {
                                    return <>
                                        <TextfieldInput name={Consts.fieldTranslation[key] || key} tag={"user_" + key} onBlur={this.checkPostalCode}/>
                                        {postalCode !== undefined && (postalCode !== null ? <>{postalCode.city} ({postalCode.state})</> : <span style={{color: "red"}}>Postleitzahl nicht gefunden!</span>)}
                                        {i % 2 === 1 && <br/>}
                                    </>
                                }
                                return <InputContainer>
                                    <TextfieldInput name={Consts.fieldTranslation[key] || key} tag={"user_" + key}/>
                                    {i % 2 === 1 && <br/>}
                                </InputContainer>
                            })
                    }
                    <br/>
                    <TagsInput multiple name={"Verein"} entity={"club"} ID={0} tag={"user_clubs"}/>
                    <br/>
                    {
                        registerMode && [<div style={{clear: "both"}}>
                            <div style={{paddingBottom: "", float: "left", paddingRight: "10px"}}>
                                <CheckboxInput name={"akzeptiere"} tag={"user_dataprotection"} noLabel/>
                            </div>
                            <div>
                                <br/>
                                <h2>Einwillung DSGVO</h2>
                                Ich gebe mit der Anmeldung auf der Plattform „Turnfest Dahoam“ die Einwilligung zu nachstehenden Punkten zum Zweck der Durchführung von Veranstaltung/Wettkämpfen durch den ÖTB Oberösterreich und seinen beauftragten Personen, Verwaltern und Wettkampforganisatoren:
                                <br/>
                                <br/>

                                <li> Verarbeitung und Weitergabe von personenbezogenen Daten (Vorname, Familienname, Geschlecht, Geburtsjahr, E-Mail-Adresse, Vereinszugehörigkeit, Wertungen, Punkte) der gemeldeten Person. Insofern Jugendliche oder Kinder teilnehmen, liegt die Einwilligung des
                                    jeweils Erziehungsberechtigten vor.
                                </li>
                                <li>Veröffentlichung von Meldedaten, Ergebnissen, Wertungen, Fotos, Bildern und Videos in Berichten, Medien, Zeitungen, auf den Webseiten des ÖTB OÖ und auf sozialen Medien des ÖTB OÖ oder des Vereins.</li>

                                <>Information/Hinweis</>
                                <br/>
                                Die Daten werden vom ÖTB Oberösterreich und den beauftragten und verantwortlichen Personen nur zu den beschriebenen Zwecken verarbeitet, gespeichert und verwendet.<br/>
                                Eine Weitergabe der Daten erfolgt gegebenenfalls an
                                <li> die Dachverbände ÖTB, ASVÖ und ASVOÖ, sowie den Landesverbänden ÖFT, und OÖFT</li>
                                <li> externe Veranstalter/Organisationen von Ausbildungen, Kursen, Wettkämpfen jeweils zum Zweck der Leistungs- und Ergebniserfassung und des Ergebnismanagements</li>
                                <li> Medien zum Zwecke der Berichterstattung über sportliche und vereinsbezogene Veranstaltungen.</li>
                                Es besteht keine Absicht, die Daten an Dritte, in ein Drittland oder an eine internationale Organisation zu übermitteln.<br/>
                                Aus statistischen Gründen und rechtlichen Haftungsgründen ist eine Speicherung der Daten für die Dauer der allgemeinen Verjährungsfrist von 30 Jahren vom ÖTB Oberösterreich beabsichtigt

                            </div>
                        </div>,
                            <br/>,
                            <div style={{clear: "both"}}>
                                <div style={{paddingBottom: "", float: "left", paddingRight: "10px"}}>
                                    <CheckboxInput name={"akzeptiere"} tag={"user_communication"} noLabel/>
                                </div>
                                <br/>
                                <div>
                                    <h2>Einwilligung ÖTB OÖ Informationen</h2>
                                    Ja, ich möchte in Zukunft Informationen über ÖTB OÖ Veranstaltungen, Wettkämpfe, Aus- und Fortbildungen.
                                    <br/>
                                    <br/>

                                </div>
                            </div>]
                    }
                    <br/>
                    <MaxBtn>{registerMode ? "Registrieren" : "Speichern"}</MaxBtn>
                </FormContextWrapper>
            }

        </Container>
    }
}
