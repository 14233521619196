import React, {useMemo, useState} from "react";
import {maxiGetObj} from "../core/maxiosLeg";
import {Loader, MaxBtn} from "../core/components";
import Status from "../core/status";
import Blocks from "./blocks"
import {UserContext} from "../user/UserContext";
import "./blocks.sass"
import {LightContainer, MyModal, SelectfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {apiPost} from "../core/api";
import Consts from "../core/consts";

const types = {
    0: "Text",
    1: "Video",
    2: "Document",
};

export default function PageView({slot}) {
    const [blocks, setBlocks] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [success,] = useState("");
    const [editMode, setEditMode] = useState(Consts.f(false, false));

    const load = () => {
        console.log("reload", blocks)
        maxiGetObj({
            url: "/page_blocks/" + slot, success: resp => {
                setBlocks({...resp.blocks});
                setLoading(false);
            }, setError: e => {
                setLoading(false);
                setError(e.toString())
            }
        });
    }

    useMemo(() => {
        load()
    }, [slot]);
    const pageLength = blocks !== null && Object.keys(blocks).length || 0;

    return <>
        <Status type={"error"} text={error}/>
        <Status type={"success"} text={success}/>
        <Loader loading={loading}/>

        {
            blocks !== null && Object.keys(blocks).length === 0 && ""
        }
        {
            blocks !== null &&
            Object.entries(blocks).map(([key, block]) => {
                const typeString = types[block.type] || "Text";
                const BlockComponent = Blocks[typeString];
                return <div className={"blockContainer " + (editMode ? "editMode" : "") + " " + typeString}>
                    <BlockComponent {...{editMode, pageLength, block}} reload={load}/>
                </div>
            })
        }
        <UserContext.Consumer>
            {
                context =>
                    context.user !== undefined && context.user.role > 50 &&
                    <div className={"blockMenu"}>
                        <br/>
                        {
                            editMode &&
                            <AddBlock {...{slot, load}}/>
                        }

                        <MaxBtn className={"rightmost"} onClick={() => {
                            load();
                            setEditMode(!editMode)
                        }}>{editMode ? "Anschauen" : "Bearbeiten"}</MaxBtn>

                    </div>

            }
        </UserContext.Consumer>
    </>
}

class AddBlock extends React.Component {
    apiPost = apiPost.bind(this);
    setState = this.setState.bind(this);
    state = {
        block: {
            type: 0,
        }
    };
    handleSubmit = close => e => {
        e.preventDefault();
        this.setState({loading: true});
        this.apiPost("/page_blocks/add/" + this.props.slot, this.state.block, _ => {
            this.props.load();
            close();
        })
    };

    render() {
        return <MyModal trigger={<MaxBtn>Hinzufügen</MaxBtn>}>
            {
                close =>
                    <LightContainer name={"Block hinzufügen / Typ auswählen"}>
                        <Status type={"error"} text={this.state.error}/>
                        <FormContextWrapper value={{state: this.state, setState: this.setState}}
                                            onSubmit={this.handleSubmit(close)}>
                            <SelectfieldInput name={"Block-Typ"} demandSelect tag={"block_type"}
                                              selectives={Object.entries(types)}/>
                            <br/>
                            <MaxBtn>Hinzufügen</MaxBtn>
                            <Loader loading={this.state.loading}/>
                        </FormContextWrapper>

                    </LightContainer>
            }
        </MyModal>

    }
}
