/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
import {FaBuilding, FaDumbbell, FaList, FaQuestion, FaSignOutAlt, FaTrophy, FaUserAlt, FaUsers} from "react-icons/fa";
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom"
import {UserContext} from "./user/UserContext"
import {Redirect} from "react-router";
import {apiGet} from "./core/api";
import {FaBars} from "react-icons/all";
import LogoMin from "./images/logo_min.svg";


const NavContext = React.createContext();

export default class Nav extends React.Component {
    static contextType = UserContext;

    apiGet = apiGet.bind(this);

    constructor(props) {
        super(props);
        this.state = {open: ""};
        this.pn = this.props.location.pathname;
        if (this.pn.indexOf("buchhaltung") > -1) {
            this.state.open = "Buchhaltung"
        }
        if (this.pn.indexOf("newsletter") > -1) {
            this.state.open = "Newsletter"
        }
        this.setState = this.setState.bind(this);
    }


    render() {
        let {user, club} = this.context;
        const logged_in = user?.role !== undefined;
        return <NavContext.Provider value={{state: this.state, location: this.props.location, toggleMenuItem: this.toggleMenuItem}}>

            <header className={"MobileHeader"}>
                <FaBars id={"menuexpander"} onClick={this.context.navToggle}/>
                <span>{club.shortName}</span>
            </header>
            <header className={"MobileHeaderBehind"}/>
            <header className={"App-header " + (this.context.navOpen ? "open" : "closed")}>
                <div>


                    <a>
                        <FaBars id={"menuexpander"} onClick={this.context.navToggle}/>
                        <Link to={"/"} style={{paddingRight: 0}}>
                            <img width={80} height={"auto"} src={LogoMin} alt={"Turnfest Logo"}/>
                            <span>Startseite</span>
                        </Link>
                    </a>
                    <NavItem
                        child={{
                            link: "/veranstaltungen",
                            icon: <FaDumbbell className={"icon"}/>,
                            text: "Veranstaltungen"
                        }}
                    />
                    <NavItem
                        child={{
                            link: "/faq",
                            icon: <FaQuestion className={"icon"}/>,
                            text: "FAQ"
                        }}
                    />
                    <NavItem
                        child={{
                            link: "/ergebnisse",
                            icon: <FaList className={"icon"}/>,
                            text: "Ergebnisse"
                        }}
                    />
                    <NavItem
                        child={{
                            link: "/ranglisten",
                            icon: <FaTrophy className={"icon"}/>,
                            text: "Rangliste"
                        }}
                    />
                    <NavItem
                        child={{
                            link: "/vereine",
                            icon: <FaBuilding className={"icon"}/>,
                            text: "Vereine"
                        }}
                    />
                    <NavItem
                        child={{
                            link: "/personen",
                            icon: <FaUsers className={"icon"}/>,
                            text: "Personen"
                        }}
                    />
                    {
                        logged_in &&
                        <NavItem
                            child={{
                                link: "/profil",
                                icon: <FaUserAlt className={"icon"}/>,
                                text: "Mein Profil"
                            }}
                        />
                    }

                    <a href={(logged_in ? "#" : "/benutzer/login")} onClick={(e) => {
                        if (logged_in) {
                            this.apiGet("/user/logout", _ => window.location.href = "/");
                            return false;
                        }
                    }
                    }>
                        <FaSignOutAlt className={"icon"}/>
                        <span>{logged_in ? "Logout" : "Login"}</span>
                    </a>
                </div>
            </header>


            {/*pn.split("/").length > 1 && ["course", "my"].indexOf(pn.split("/")[1]) < 0 &&
            <div id={"behindnav"}/>
            */}
        </NavContext.Provider>
    }
}


const SubNav = ({name, subs, icon, link}) => {
    const mySubs = useMemo(() => subs.filter(a => a !== null), []);
    const visitingSubchild = useMemo(() => mySubs.reduce((obj, curr) => obj || curr.link === window.location.pathname, false), []);

    const [navOpen, setOpen] = useState(localStorage.getItem("navOpen" + name) === "true" || visitingSubchild);
    const [redirectContainer, setRedirectContainer] = useState(null);
    useEffect(_ => {
        localStorage.setItem("navOpen" + name, navOpen)
    });

    let linkFirstSplit = mySubs[0].link.split("/");
    const pathnameSplit = window.location.pathname.split("/");


    let isCurrent = false;

    if (pathnameSplit.length > 1) {
        isCurrent = pathnameSplit[1] === linkFirstSplit[1]
    }

    const onClick = () => {
        let isCurrent = false;
        if (pathnameSplit.length > 1) {
            isCurrent = pathnameSplit[1] === linkFirstSplit[1]
        }
        setOpen(((!isCurrent && !navOpen) || isCurrent) ? true : !navOpen);
        setRedirectContainer(!isCurrent && !navOpen ? <Redirect to={mySubs.link}/> : null)
    };


    return <>
        <Link onHover={onClick} to={link} className={isCurrent ? "current" : "noncurrent"}>
            {icon}
            <span>{name}</span>
        </Link>
        {redirectContainer}
        {navOpen &&
        <em>
            {mySubs.map((child) => {
                return <NavItem key={"child" + child.text} parentOfSubnav child={child}/>
            })
            }
        </em>
        }
    </>

};

const NavItem = ({child, parentOfSubnav, reloadRedir}) => {
    //const context = useContext(NavContext);
    let isCurrent = false;
    const {club} = useContext(UserContext);
    const pathnameSplit = decodeURI(window.location.pathname).split("/");
    const location = pathnameSplit.slice(0, 2 + 1).join("/");

    if (pathnameSplit.length > 1) {
        isCurrent = pathnameSplit[1] === child.link.split("/")[1]
    }
    if (parentOfSubnav && child.link !== location) {
        isCurrent = false;
    }
    useEffect(() => {
        if (isCurrent) {
            document.title = `${child.text} - ` + club.title
        }
    });
    const userContext = useContext(UserContext);
    //console.log(child.link,location)
    const onClick = () => {
        if (window.innerWidth < 1000 && userContext.navOpen) {
            userContext.navToggle()
        }
    };
    const NormalAnchor = ({children, to, ...props}) => <a href={to} {...props}>{children}</a>;
    const LinkNode = !!reloadRedir ? NormalAnchor : Link;
    return <LinkNode to={child.link !== undefined && child.link !== "" ? child.link : "#"} onClick={onClick}
                     className={isCurrent ? "current" : "noncurrent"}>
        {child.icon}
        <span>{child.text}</span>
    </LinkNode>

};
