import React, {createRef} from 'react';
import {Container, Loader, MaxBtn, TextfieldInput} from "../core/input_fields"
import Status from "../core/status"
import {apiGet, apiPost} from "../core/api";
import {Club} from "../core/custom";
import {FormContextWrapper} from "../core/form_context";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import "./login.sass"
import "../general.sass"

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            action: "login",
        };
        this.setState = this.setState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.usernameField = createRef();
        const pn = this.props.location.pathname || "";
        if (pn.substr(0, 16) === "/benutzer/logout") {
            this.apiGet("/user/logout",_=>window.location.href="/");
        } else {
            this.apiGet("/user/status", resp => {
                if (resp.status === "logged_in") {
                    if (props.redir === undefined) {
                        let t = localStorage.getItem("redir");
                        localStorage.setItem("redir", "");
                        if (t === "0" || t === "" || t === null || t === "/0" || t === undefined || t.substr(0, 15) === "/benutzer/login" || t.substr(0, 16) === "/benutzer/logout") {
                            t = "/";
                        }
                        console.log("redir to ", t)
                        window.location.href = t
                    } else {
                        console.log("redir to ", props.redir)
                        window.location.href = props.redir
                    }


                }
            });

        }


    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value
        })

    }

    componentDidMount() {
        let usernameField = this.usernameField.current;
        if (usernameField !== null) {
            usernameField.focus()
        }

    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true})
        const {action} = this.state;
        if (action === "pwreset") {
            this.apiPost("/user/request_pwreset", this.state);
            return;
        }
        this.apiPost("/user/login", this.state, resp => {
            this.setState({
                error: "",
                status: resp.message,
            });
            if (this.props.redir === undefined) {
                let t = localStorage.getItem("redir")
                if (t === "0" || t === "" || t === null || t === "/0" || t === undefined || t.substr(0, 15) === "/benutzer/login" || t.substr(0, 16) === "/benutzer/logout") {
                    t = "/";
                }
                localStorage.setItem("redir", "/");
                window.setTimeout(() => {
                    console.log("redir to ", t)
                    window.location.href = t
                }, 500)
            } else {
                console.log("redir to ", this.props.redir)
                window.location.href = this.props.redir
            }
        });

    }

    render() {
//        console.log(this.props)
        //<img src={Porträt} style={{float: "right"}} width={"300px"} className={"animgrey"}/>
        const {action, error, loading} = this.state;
        return (
            <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>

                <Container name={"Login"} hideHeading visible>

                    <h2>Login</h2>
                    <Status type="error" text={error}/>
                    {
                        action === "login" && <>
                            <TextField name={"username"} label={"E-Mail"} placeholder={"max.mustermann@beispiel.at"} ref={this.usernameField}/><br/>
                            <TextField name={"password"} type={"password"} label={"Passwort"}/>
                            <br/>
                            <em onClick={() => this.setState({action: "pwreset"})}>Passwort vergessen?</em>
                            <MaxBtn>Log In</MaxBtn>
                            <p>Du hast noch keinen Account? Dann gehe zu <Link to={"/registrieren"}>Registrierung</Link>!</p>
                        </>
                    }
                    {
                        action === "pwreset" && <>
                            <Status type="success" text={this.state.status}/>
                            <TextfieldInput tag={"email"} name={"E-Mail"} ph={"max.mustermann@beispiel.at"}/><br/>
                            {/* <DateInput name={"Geburtsdatum"} tag={"birthdate"}/><br/>*/}
                            <MaxBtn>Passwort-Zurücksetz-Link anfordern</MaxBtn>
                        </>
                    }
                    {/*<TextfieldInput name={"E-Mail"} tag={"username"} style={{width: "260px"}} state={this.state}/><br/>
                    <TextfieldInput name={"Passwort"} tag={"password"} style={{width: "260px"}} type={"password"} state={this.state}/><br/>
                    */}
                    <br/>
                    <Loader loading={loading}/>
                </Container>
            </FormContextWrapper>
        )
    }
}

export default Login;
