import React from 'react';

const FormContext = React.createContext("formcontext");

class FormContextWrapper extends React.Component {
    handleChange = (e) => {
        if (!e.target.name){
            return
        }
        const s = e.target.name.split("_");

        const val = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
        const valueContainer = s.length > 1 ? {...this.props.value.state[s[0]], [s[1]]: val} : val;
        this.props.value.setState({[s[0]]: valueContainer});
        if (this.props.afterUpdate !== undefined) {
            this.props.afterUpdate(s[0], s[1], valueContainer)
        }
    };

    render() {
        return <FormContext.Provider value={this.props.value}>
            <form onSubmit={this.props.onSubmit !== undefined ? this.props.onSubmit : (e) => e.preventDefault()}
                  onChange={this.handleChange}
                  style={this.props.style || {}}
            >
                {this.props.children}
            </form>
        </FormContext.Provider>
    }
}

export {FormContext, FormContextWrapper};