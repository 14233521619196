import React from "react";
import "./status.sass"
import {FaWindowClose} from "react-icons/fa";

class Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayStyle: "block",
            textNotShowing: ""
        };
        this.ref = React.createRef();
    }

    typeTranslations = {
        error: "Fehler",
        success: "Erfolgreich",
        successRegistration: "Registrierung Erfolgreich",
    }

    componentDidUpdate() {
        if ((this.props.text === "" || this.props.text === undefined) && (this.props.children === undefined || this.props.children[0] === false)) {
            return
        }
        //window.scrollTo(0, 0);
        /*let a=document.getElementById("status_"+this.props.type);

        if (a!=null) {

            //a.parentElement.scrollIntoView()
        }*/
    }

    render() {
        /*
        if (this.props.text !== this.state.textNotShowing) {
            this.setState({
                displayStyle: "block",
                textNotShowing: this.props.text
            });
            return null
        }*/

        if (this.props.text === "" || this.props.text === undefined) {
            return null
        }
        return <div id={"status_" + this.props.type} className={"status " + this.props.type}
                    style={{display: this.state.displayStyle}}>
            <FaWindowClose style={{float: "right"}} onClick={() => {
                this.setState({
                    displayStyle: "none",
                })
            }}/>
            <h2>
                {this.typeTranslations[this.props.type] === undefined && this.props.type.substr(0, 1).toUpperCase() + this.props.type.substr(1)}

                {this.typeTranslations[this.props.type] !== undefined && this.typeTranslations[this.props.type]}
            </h2>
            <div>{this.props.text} {this.props.children}</div>
        </div>

    }
}

export default Status;